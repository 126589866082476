import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  ListGroup,
  Nav,
  Navbar,
  Offcanvas,
  Row,
  Tab,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../images/tipmaid-newlogo-white.png";
import { faCircleExclamation, faGear } from "@fortawesome/free-solid-svg-icons";
import Attendants from "./Attendants";
import Reports from "./Reports";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUserDetails,
  updateUserProfile,
  userLogout,
} from "../redux/Actions/userAction";
import AddRoles from "./AddRoles";
import AddUser from "./AddUser";
import Loader from "../components/Loader";
import Message from "../components/Message";
import Valets from "./Valets";
import Bellman from "./Bellman";
import Documents from "./Documents";
import ClientStats from "./ClientStats";

const Dashboard = () => {
  const [defaultActiveKey, setDefaultActiveKey] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [show, setShow] = useState(false);
  // const [showAlert, setShowAlert] = useState(false);
  const userInfo = useSelector((state) => state.userDetails);
  const {
    loading: updateLoading,
    success: updateSuccess,
    error: updateError,
  } = useSelector((state) => state.userUpdate);
  const { loading, error, user } = userInfo;

  // --------------------------------------------------PERMISSIONS --------------------------------------------------
  let isAdmin = false;
  let canRead = false;
  let toDownload = false;
  let toAddMaid = false;
  let toDeleteMaid = false;
  let viewAttendants = false;
  let viewValets = false;
  let viewBellmans = false;
  let adminServiceMaids = false;
  let adminServiceValets = false;
  let adminServiceBellmen = false;
  let activeDashboard;

  if (user.isAdmin == false) {
    canRead = user.roles.permissions.read;
    toDownload = user.roles.permissions.download;
    toAddMaid = user.roles.permissions.addMaid;
    toDeleteMaid = user.roles.permissions.deleteMaid;
    viewAttendants = user.roles.permissions.viewAttendants;
    if (viewAttendants) {
      activeDashboard = "#attendants";
    }
    viewValets = user.roles.permissions.viewValets;
    if (viewValets) {
      activeDashboard = "#valet";
    }
    viewBellmans = user.roles.permissions.viewBellmans;
    if (viewBellmans) {
      activeDashboard = "#bellman";
    }

    isAdmin = user.isAdmin;
  } else if (user.isAdmin == true) {
    isAdmin = true;
    if (user.services.maids == true) {
      adminServiceMaids = true;
      activeDashboard = "#attendants";
    }
    if (user.services.valets == true) {
      adminServiceValets = true;
      activeDashboard = "#valet";
    }
    if (user.services.bellmen == true) {
      adminServiceBellmen = true;
      activeDashboard = "#bellman";
    }
  }
  // -----------------------------------------------------------------------------------------------------------------

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
    if (user.isAdmin == true) {
      setName(user.companyName);
      setEmail(user.email);
    } else {
      setName(user.name);
      setEmail(user.email);
    }
  };
  const handleSaveChanges = () => {
    if (password == confirmPassword) {
      setPasswordMatch(false);
      const updatedUser = {
        name,
        email,
        password,
      };
      dispatch(updateUserProfile(updatedUser));
    } else {
      setPasswordMatch(true);
    }
  };

  const handleLogout = () => {
    dispatch(userLogout());
    navigation("/login");
  };

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch, passwordMatch, updateSuccess]);

  useEffect(() => {
    if (user.isAdmin === false) {
      if (viewAttendants) {
        setDefaultActiveKey("#attendants");
      } else if (viewValets) {
        setDefaultActiveKey("#valet");
      } else if (viewBellmans) {
        setDefaultActiveKey("#bellman");
      } else {
        setDefaultActiveKey("#attendants");
      }
    } else if (user.isAdmin === true) {
      if (adminServiceMaids) {
        setDefaultActiveKey("#attendants");
      } else if (adminServiceValets) {
        setDefaultActiveKey("#valet");
      } else if (adminServiceBellmen) {
        setDefaultActiveKey("#bellman");
      } else {
        setDefaultActiveKey("#attendants");
      }
    }
  }, [
    user,
    viewAttendants,
    viewValets,
    viewBellmans,
    adminServiceMaids,
    adminServiceValets,
    adminServiceBellmen,
  ]);

  return (
    <>
      {/* ------------------------------------- USER EDIT OFF CANVAS --------------------------------------------- */}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fw-bold">
            {/* {user.companyName} */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row className="h-100">
            <Col>
              <h5 className="text-muted text-center mt-5">Edit Profile</h5>
              <hr className="mb-5" />
              {updateLoading && <Loader />}
              {updateError && <Message>{updateError}</Message>}
              {/* {updateSuccess || showAlert ? (
                <Alert variant="success" className="text-center" dismissible>
                  Updated successfully!
                </Alert>
              ) : (
                <></>
              )} */}
              <Row className="align-items-center">
                <Col md={5}>Name</Col>
                <Col md={7}>
                  <InputGroup className="my-3">
                    <Form.Control
                      type="text"
                      className="border-0 border-bottom"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={5}>Email</Col>
                <Col md={7}>
                  <InputGroup className="my-3">
                    <Form.Control
                      type="email"
                      className="border-0 border-bottom"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={5}>New Password</Col>
                <Col md={7}>
                  <InputGroup className="my-3">
                    <Form.Control
                      type="password"
                      className="border-0 border-bottom"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={5}>Confirm Passowrd</Col>
                <Col md={7}>
                  <InputGroup className="my-3">
                    <Form.Control
                      type="password"
                      className="border-0 border-bottom"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      aria-describedby="basic-addon1"
                    />
                  </InputGroup>
                  {passwordMatch ? (
                    <Form.Text className="text-danger">
                      Passwords does not match
                    </Form.Text>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row className="sticky-bottom mt-5">
                <Col className="align-self-end mx-auto">
                  <Row className="mt-5">
                    <Col md={6}>
                      <Row className="pe-2">
                        <Button
                          variant="danger"
                          className="d-grid"
                          onClick={handleLogout}
                        >
                          LogOut
                        </Button>
                      </Row>
                    </Col>
                    <Col md={6}>
                      <Row className="pe-2">
                        <Button
                          variant="primary"
                          className="d-grid"
                          onClick={handleSaveChanges}
                        >
                          Save Changes
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      {/* --------------------------------------------- NAVBAR -------------------------------------------------- */}
      <Navbar bg="primary " variant="dark" className="py-3">
        <Container className="justify-content-center">
          <Navbar.Brand className="fs-4">
            <img
              src={logo}
              alt="TipMaid"
              width="150"
              // height="100"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
        </Container>
      </Navbar>
      {/* -------------------------------------------- DASHBOARD TAB -------------------------------------------- */}
      {user && defaultActiveKey && (
        <Tab.Container
          id="list-group-tabs-example"
          defaultActiveKey={defaultActiveKey || activeDashboard}
        >
          <Row className="m-0">
            <Col sm={2} className="bg-primary px-4 vh-100 sticky-top">
              <Row className="h-100 pt-5">
                <ListGroup variant="flush">
                  {adminServiceMaids || viewAttendants ? (
                    <>
                      <ListGroup.Item
                        className="bg-primary text-center fw-bold fs-5"
                        action
                        variant="light"
                        href="#attendants"
                      >
                        Attendants
                      </ListGroup.Item>
                      <hr className="text-light" />
                    </>
                  ) : (
                    <></>
                  )}
                  {adminServiceValets || viewValets ? (
                    <>
                      <ListGroup.Item
                        className="bg-primary text-center fw-bold fs-5"
                        action
                        variant="light"
                        href="#valet"
                      >
                        Valet
                      </ListGroup.Item>
                      <hr className="text-light" />
                    </>
                  ) : (
                    <></>
                  )}
                  {adminServiceBellmen == true || viewBellmans ? (
                    <>
                      <ListGroup.Item
                        className="bg-primary text-center fw-bold fs-5"
                        action
                        variant="light"
                        href="#bellman"
                      >
                        Bell Men
                      </ListGroup.Item>
                      <hr className="text-light" />
                    </>
                  ) : (
                    <></>
                  )}
                  {user.isAdmin == true ? (
                    <>
                      <ListGroup.Item
                        className="bg-primary text-center fw-bold fs-5"
                        action
                        variant="light"
                        href="#roles"
                      >
                        Create Roles
                      </ListGroup.Item>
                      <hr className="text-light" />
                    </>
                  ) : (
                    <></>
                  )}
                  {user.isAdmin == true ? (
                    <>
                      <ListGroup.Item
                        className="bg-primary text-center fw-bold fs-5"
                        action
                        variant="light"
                        href="#adduser"
                      >
                        Add user
                      </ListGroup.Item>
                      <hr className="text-light" />
                    </>
                  ) : (
                    <></>
                  )}
                  {user.isAdmin == true ? (
                    <>
                      <ListGroup.Item
                        className="bg-primary text-center fw-bold fs-5"
                        action
                        variant="light"
                        href="#stats"
                      >
                        Stats
                      </ListGroup.Item>
                      <hr className="text-light" />
                    </>
                  ) : (
                    <></>
                  )}
                  {user.isAdmin == true ? (
                    <>
                      <ListGroup.Item
                        className="bg-primary text-center fw-bold fs-5"
                        action
                        variant="light"
                        href="#documents"
                      >
                        Documents
                      </ListGroup.Item>
                      <hr className="text-light" />
                    </>
                  ) : (
                    <></>
                  )}
                </ListGroup>

                <Col className="align-self-end text-center fs-4 text-light fw-3 mb-3">
                  <hr />
                  <Row className="d-grid">
                    <Button size="lg" onClick={handleShow}>
                      <FontAwesomeIcon className="px-2" icon={faGear} />
                      Settings
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col sm={10} className="pe-5">
              <Tab.Content>
                <Tab.Pane eventKey="#attendants">
                  <Attendants
                    toAddMaid={toAddMaid}
                    toDeleteMaid={toDeleteMaid}
                    toDownload={toDownload}
                    isAdmin={isAdmin}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="#valet">
                  <Valets
                    toAddMaid={toAddMaid}
                    toDeleteMaid={toDeleteMaid}
                    toDownload={toDownload}
                    isAdmin={isAdmin}
                    canRead={canRead}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="#bellman">
                  <Bellman
                    toAddMaid={toAddMaid}
                    toDeleteMaid={toDeleteMaid}
                    toDownload={toDownload}
                    isAdmin={isAdmin}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="#roles">
                  <AddRoles />
                </Tab.Pane>
                <Tab.Pane eventKey="#adduser">
                  <AddUser />
                </Tab.Pane>
                <Tab.Pane eventKey="#stats">
                  <ClientStats />
                </Tab.Pane>
                <Tab.Pane eventKey="#documents">
                  <Documents />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      )}
    </>
  );
};

export default Dashboard;
