import {
  faMagnifyingGlass,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Circle } from "rc-progress";
import { Doughnut, Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  FormControl,
  Row,
} from "react-bootstrap";
import TotalSum from "../statsComponents/TotalSum";
import StaffStats from "../statsComponents/StaffStats";
import { useDispatch, useSelector } from "react-redux";
import {
  calculateBellmanStats,
  calculateIndividualTipStats,
  calculateMaidStats,
  calculateRefundedTransactionStats,
  calculateTeamTipStats,
  calculateTotalCommissionCharged,
  calculateTotalStripeFee,
  calculateTotalSubcharged,
  calculateTotalSum,
  calculateValetStats,
  getMonthlyData,
  getUnverifiedTeamTips,
  getVerifiedTeamTips,
  getWeeklyData,
} from "../controllers/stats";
import Loader from "../components/Loader";
import { getAllStaff, getAllValetTeams } from "../redux/Actions/maidAction";
import { listUsers } from "../redux/Actions/userAction";

Chart.register(...registerables);
const Stats = () => {
  // const users = ["Alice", "Bob", "Charlie", "David"];
  const [query, setQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [fliterApplied, setFilterApplied] = useState(false);
  const [reset, setReset] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [monthlyData, setMonthlyData] = useState({});
  const [weeklyData, setWeeklyData] = useState({});
  const [doughnutData, setDoughnutData] = useState(null);
  const [dailyData, setDailyData] = useState({});
  const [mergedTransactionsInfo, setMergedTransactionsInfo] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [staff, setStaff] = useState([]);
  const [valetTeams, setValetTeams] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);

  const { payments } = useSelector((state) => state.allPayments);
  const allPyaments = payments
    ? payments.filter((payment) => payment.teamTip !== true)
    : [];
  const transactionsList = useSelector((state) => state.transactionsList);
  const {
    loading: transactionsLoading,
    error: transactionsError,
    transactions = [],
  } = transactionsList;
  const {
    staff: staffData,
    loading: staffLoading,
    error: staffError,
  } = useSelector((state) => state.getAllStaff);
  const {
    valetTeams: valetTeamsData,
    loading: valetTeamsLoading,
    error: valetTeamsError,
  } = useSelector((state) => state.getAllValetTeams);

  const {
    loading: usersLoading,
    error: usersError,
    users,
  } = useSelector((state) => state.userList);
  const clients =
    users && users.length > 0
      ? users.filter((user) => !user.isSuperAdmin && !user.isPartner)
      : [];

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const input = e.target.value;
    setSelectedUserId(null);
    setQuery(input);
    if (input.length > 0 && clients.length > 0 && staff.length > 0) {
      const matches = clients.filter((user) =>
        user.companyName.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredUsers(matches);
      setShowDropdown(matches.length > 0);
    } else {
      setShowDropdown(false);
      setErrorMessage(true);
    }
  };

  const handleStartDateChange = (e) => {
    if (
      staff.length > 0 &&
      mergedTransactionsInfo.length > 0 &&
      valetTeams.length > 0
    ) {
      setStartDate(e.target.value);
      setErrorMessage(false);
    }
    setErrorMessage(true);
  };
  const handleEndDateChange = (e) => {
    if (
      staff.length > 0 &&
      mergedTransactionsInfo.length > 0 &&
      valetTeams.length > 0
    ) {
      setEndDate(e.target.value);
      setErrorMessage(false);
    }
    setErrorMessage(true);
  };
  const handleSelectUser = (user) => {
    setQuery(user.companyName);
    setSelectedUserId(user._id);
    setShowDropdown(false);
  };
  const handleFilter = () => {
    setFilterApplied(true);
    let filteredTransactions = [...mergedTransactionsInfo];
    let filteredStaff = [...staff];
    let filteredValetTeams = [...valetTeams];

    // Filter by selectedUserId if it's present
    if (selectedUserId) {
      console.log("Filtering by selected UserId");
      filteredTransactions = filteredTransactions.filter(
        (transaction) => transaction.clientId === selectedUserId
      );
      filteredStaff = filteredStaff.filter(
        (user) => user.hotel === selectedUserId
      );
      filteredValetTeams = filteredValetTeams.filter(
        (team) => team.hotel === selectedUserId
      );
    }

    // Filter by date range if both startDate and endDate are provided
    if (startDate && endDate) {
      console.log("Filtering by start & end date");
      const start = new Date(startDate);
      const end = new Date(endDate);

      filteredTransactions = filteredTransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.date);
        return transactionDate >= start && transactionDate <= end;
      });
      filteredStaff = filteredStaff.filter((user) => {
        const userCreatedAt = new Date(user.createdAt);
        return userCreatedAt >= start && userCreatedAt <= end;
      });
      filteredValetTeams = filteredValetTeams.filter((team) => {
        const teamCreatedAt = new Date(team.createdAt);
        return teamCreatedAt >= start && teamCreatedAt <= end;
      });
    }

    setFilteredTransactions(filteredTransactions);
    setStaff(filteredStaff);
    setValetTeams(filteredValetTeams);
  };

  const clearFilters = () => {
    setFilterApplied(false);
    setErrorMessage(false);
    setStartDate("");
    setEndDate("");
    setQuery("");
    setSelectedUserId(null);
    setShowDropdown(false);
    console.log("Staff in clear filters: ", staff);
    // setMergedTransactionsInfo(mergedTransactionsInfo);
    setStaff(staffData);
    setValetTeams(valetTeamsData);
  };

  useEffect(() => {
    dispatch(getAllValetTeams());
    // dispatch(getAllStaff());
    dispatch(listUsers());
  }, []);

  useEffect(() => {
    if (transactions.length > 0 && allPyaments.length > 0) {
      console.log("If statement in use effect was true");
      const mergedTransactionsInfo = allPyaments.map((payment) => {
        const matchingTransaction = transactions.find(
          (transaction) => transaction.payment_intent == payment.paymentIntentId
        );
        return {
          paymentIntentId: payment.paymentIntentId,
          clientId: payment.clientId,
          date: payment.createdAt,
          clientName: payment.clientName,
          sentTo:
            payment.connectAccount !== "Team Payment"
              ? payment.connectAccount
              : "Team tip",
          tipAmount: payment.tipAmount,
          ...(payment.commissionCharged && {
            commissionCharged: 0.1,
            partnerId: payment.partnerId,
          }),
          totalAmount: payment.amount,
          processingFee: payment.commissionCharged
            ? parseFloat(payment.processingFee - 0.1).toFixed(2)
            : payment.processingFee,
          stripeFee: payment.stripeFee,
          paymentMethod: matchingTransaction?.payment_method_details?.card
            ?.wallet
            ? matchingTransaction.payment_method_details.card.wallet.type
            : "Card Payment",
          disputed: matchingTransaction?.disputed,
          refunded: matchingTransaction?.refunded,
        };
      });
      setMergedTransactionsInfo(mergedTransactionsInfo);
    }
  }, [transactionsLoading]);

  useEffect(() => {
    if (staffData?.length > 0) {
      setStaff(staffData);
    }
    if (valetTeamsData?.length > 0) {
      setValetTeams(valetTeamsData);
    }
  }, [staffData, valetTeamsData]);

  useEffect(() => {
    // Calculate and set the monthly data
    if (mergedTransactionsInfo && mergedTransactionsInfo.length > 0) {
      const monthlyChartData = {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Transactions",
            data: getMonthlyData(
              fliterApplied ? filteredTransactions : mergedTransactionsInfo
            ),
            backgroundColor: "rgba(75,192,192,0.4)", // Teal
            borderColor: "rgba(75,192,192,1)", // Teal
            borderWidth: 2,
            fill: true,
          },
        ],
      };
      setMonthlyData(monthlyChartData);

      // Calculate and set the weekly data
      let targetMonth;
      let targetYear;
      if (startDate && endDate) {
        const start = new Date(startDate);
        targetMonth = start.getMonth();
        targetYear = start.getFullYear();
      } else {
        targetMonth = new Date().getMonth();
        targetYear = new Date().getFullYear();
      }
      const weeklyDataCounts = getWeeklyData(
        fliterApplied ? filteredTransactions : mergedTransactionsInfo,
        targetMonth,
        targetYear
      );
      const weeklyChartData = {
        labels: Array.from({ length: 31 }, (_, i) => i + 1),
        datasets: [
          {
            label: "Week 1",
            data: weeklyDataCounts.datasets[0].data,
            borderColor: "rgba(75, 192, 192, 1)", // Teal
            backgroundColor: "rgba(75, 192, 192, 0.2)", // Teal (transparent)
            borderWidth: 2,
            fill: true,
          },
          {
            label: "Week 2",
            data: weeklyDataCounts.datasets[1].data,
            borderColor: "rgba(153, 102, 255, 1)", // Purple
            backgroundColor: "rgba(153, 102, 255, 0.2)", // Purple (transparent)
            borderWidth: 2,
            fill: true,
          },
          {
            label: "Week 3",
            data: weeklyDataCounts.datasets[2].data,
            borderColor: "rgba(255, 159, 64, 1)", // Orange
            backgroundColor: "rgba(255, 159, 64, 0.2)", // Orange (transparent)
            borderWidth: 2,
            fill: true,
          },
          {
            label: "Week 4",
            data: weeklyDataCounts.datasets[3].data,
            borderColor: "rgba(255, 99, 132, 1)", // Red
            backgroundColor: "rgba(255, 99, 132, 0.2)", // Red (transparent)
            borderWidth: 2,
            fill: true,
          },
        ],
      };

      setWeeklyData(weeklyChartData);
    }
    // Calculate and set the doughnut chart data for valet team tips
    if (valetTeams && valetTeams.length > 0) {
      const verifiedTeamTipsCount = getVerifiedTeamTips(valetTeams);
      const unverifiedTeamTipsCount = getUnverifiedTeamTips(valetTeams);

      const doughnutChartData = {
        labels: ["Verified Tips", "Unverified Tips"],
        datasets: [
          {
            data: [verifiedTeamTipsCount, unverifiedTeamTipsCount],
            backgroundColor: ["#36A2EB", "#FF6384"],
            hoverBackgroundColor: ["#36A2EB", "#FF6384"],
          },
        ],
      };
      setDoughnutData(doughnutChartData);
    }
  }, [mergedTransactionsInfo, fliterApplied, filteredTransactions, valetTeams]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
  };

  return (
    <>
      {/* {mergedTransactionsInfo &&
        console.log(
          "Merged transactions info from stats:",
          mergedTransactionsInfo
        )} */}
      {/* {clients && console.log("Clients from stats:", clients)} */}
      {/* {staff && console.log("Staff from stats:", staff)}
      {valetTeams && console.log("Valet teams from stats:", valetTeams)} */}
      <Row className="m-0 p-4">
        <Col>
          {/* ------------------------------------------------ FILTERS ---------------------------------------------- */}
          <Row className="p-3 justify-content-center mb-5">
            <Col xs={4}>
              <Row>
                <Col>
                  <FloatingLabel label="Start Date">
                    <FormControl
                      type="date"
                      value={startDate}
                      onChange={(e) => {
                        handleStartDateChange(e);
                      }}
                      className="rounded-4 border-secondary"
                    />
                  </FloatingLabel>
                </Col>
                <Col>
                  <FloatingLabel label="End Date">
                    <FormControl
                      type="date"
                      value={endDate}
                      onChange={(e) => handleEndDateChange(e)}
                      className="rounded-4 border-secondary"
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </Col>
            <Col xs={6}>
              <FormControl
                className="p-3 rounded-4 border-secondary"
                type="text"
                value={query}
                onChange={handleInputChange}
                placeholder="Search for clients"
              />
              {/* {errorMessage ? (
                <span className="text-danger fst-italic fs-7 ps-3">
                  Please wait for the data loading
                </span>
              ) : null} */}
              {showDropdown && (
                <Dropdown show>
                  <Dropdown.Menu
                    className="rounded-4 mt-1"
                    style={{ width: "100%" }}
                  >
                    {filteredUsers.map((user, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleSelectUser(user)}
                      >
                        {user.companyName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Col>
            <Col xs="auto">
              <Button
                variant="outline-secondary"
                className="p-3 rounded-4 me-3"
                onClick={handleFilter}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} className="fs-5" />
              </Button>
              <Button
                variant="outline-secondary"
                className="p-3 rounded-4"
                onClick={clearFilters}
              >
                <FontAwesomeIcon icon={faRotateLeft} className="fs-5" />
              </Button>
            </Col>
          </Row>
          {/* ------------------------------------------------ TABS ----------------------------------------------- */}
          {/* ===================================== TRANSACTION RECORDS =============================== */}
          {transactionsLoading ? (
            <Row>
              <Loader />
              <h6 className="text-center my-1 fw-normal">
                Loding Transaction Data
              </h6>
            </Row>
          ) : (
            <Row className="border border-0 p-3 my-4">
              <Col xs={4}>
                <h5 className=" fw-normal">Total Transactions</h5>
                <span className="fw-normal text-primary fs-2 ms-5 ">
                  {
                    (fliterApplied
                      ? filteredTransactions
                      : mergedTransactionsInfo
                    ).length
                  }
                </span>
              </Col>
              <Col xs={4}>
                <h5 className=" fw-normal">Total Money Processed</h5>
                <span className="fw-normal text-success fs-2 ms-5 ">
                  $
                  {calculateTotalSum(
                    fliterApplied
                      ? filteredTransactions
                      : mergedTransactionsInfo
                  )}
                </span>
              </Col>
              <Col xs={4} className="ms-auto">
                <Row>
                  {/* ------------------------------------------ INDIVIDUAL TIPS RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateIndividualTipStats(
                            fliterApplied
                              ? filteredTransactions
                              : mergedTransactionsInfo
                          ).percentageOfIndividualTips
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#06BF9C"}
                      />
                      <div className="circle-text">
                        <span className="text-success">
                          {
                            calculateIndividualTipStats(
                              fliterApplied
                                ? filteredTransactions
                                : mergedTransactionsInfo
                            ).percentageOfIndividualTips
                          }
                          %
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-success text-center">
                        Direct tips{" "}
                        {
                          calculateIndividualTipStats(
                            fliterApplied
                              ? filteredTransactions
                              : mergedTransactionsInfo
                          ).numberOfIndividualTipTransactions
                        }
                      </span>
                    </Row>
                  </Col>
                  {/* ------------------------------------------ VALE POOL TIPS RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateTeamTipStats(
                            fliterApplied
                              ? filteredTransactions
                              : mergedTransactionsInfo
                          ).percentageOfTeamTips
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#06BF9C"}
                      />
                      <div className="circle-text">
                        <span className="text-success">
                          {
                            calculateTeamTipStats(
                              fliterApplied
                                ? filteredTransactions
                                : mergedTransactionsInfo
                            ).percentageOfTeamTips
                          }
                          %
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-success text-center">
                        Pool tips{" "}
                        {
                          calculateTeamTipStats(
                            fliterApplied
                              ? filteredTransactions
                              : mergedTransactionsInfo
                          ).numberOfTeamTipTransactions
                        }
                      </span>
                    </Row>
                  </Col>
                  {/* ------------------------------------------ REFUNDED RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateRefundedTransactionStats(
                            fliterApplied
                              ? filteredTransactions
                              : mergedTransactionsInfo
                          ).percentageOfRefundedTransactions
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#3498db"}
                      />
                      <div className="circle-text">
                        <span className="text-info">
                          {
                            calculateRefundedTransactionStats(
                              fliterApplied
                                ? filteredTransactions
                                : mergedTransactionsInfo
                            ).percentageOfRefundedTransactions
                          }
                          %
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-info text-center">
                        Refunded{" "}
                        {
                          calculateRefundedTransactionStats(
                            fliterApplied
                              ? filteredTransactions
                              : mergedTransactionsInfo
                          ).numberOfRefundedTransactions
                        }
                      </span>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          {/* ===================================== PAYMENT BREAKDOWN ================================= */}
          <Row className="my-4 justify-content-between">
            <TotalSum
              title={"Total Subcharged"}
              amount={calculateTotalSubcharged(
                fliterApplied ? filteredTransactions : mergedTransactionsInfo
              )}
              percentage={4.5}
            />
            <TotalSum
              title={"Total Stripe Fee Paid"}
              amount={calculateTotalStripeFee(
                fliterApplied ? filteredTransactions : mergedTransactionsInfo
              )}
              percentage={2.5}
            />
            <TotalSum
              title={"Total Commission Charged"}
              amount={calculateTotalCommissionCharged(
                fliterApplied ? filteredTransactions : mergedTransactionsInfo
              )}
            />
            <TotalSum
              title={"Dock $1 Revenue"}
              amount={
                (fliterApplied ? filteredTransactions : mergedTransactionsInfo)
                  .length
              }
              percentage={4.5}
            />
          </Row>
          {/* ===================================== MONTHLY & WEELY GRAPHS ============================ */}
          <Row className="my-5 pt-4">
            <Col xs={6}>
              <h5 className="text-center mb-3">Monthly Tipping Activity</h5>
              {monthlyData?.datasets?.length > 0 && (
                <Line data={monthlyData} options={options} />
              )}
            </Col>
            <Col xs={6}>
              <h5 className="text-center mb-3">
                Weekly Tipping Activity{" "}
                <span className="fs-7 text-muted">(Current Month)</span>
              </h5>
              {weeklyData?.datasets?.length > 0 && (
                <Line data={weeklyData} options={options} />
              )}
            </Col>
          </Row>
          {/* ======================================== STAFF RECORDS ================================== */}
          {staffLoading ? (
            <Row>
              <Loader />
              <h6 className="text-center my-1 fw-normal">Loding Staff Data</h6>
            </Row>
          ) : (
            <Row className="border border-0 p-3 pt-5">
              <Col xs={5}>
                <h5 className=" fw-normal text-center">Total Staff</h5>
                <h2 className="fw-normal text-primary text-center">
                  {staff.length}
                </h2>
              </Col>
              <Col xs={4} className="ms-auto">
                <Row>
                  {/* ------------------------------------------ ATTENDANTS RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={calculateMaidStats(staff).percentageOfMaids}
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#2c3e50"}
                      />
                      <div className="circle-text">
                        <span className="text-primary">
                          {calculateMaidStats(staff).percentageOfMaids}%
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-primary text-center">
                        Attendants
                        {calculateMaidStats(staff).totalMaids}
                      </span>
                    </Row>
                  </Col>
                  {/* ------------------------------------------ VALET RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={calculateValetStats(staff).percentageOfValets}
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#2c3e50"}
                      />
                      <div className="circle-text">
                        <span className="text-primary">
                          {calculateValetStats(staff).percentageOfValets}%
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-primary text-center">
                        Valets {calculateValetStats(staff).totalValets}
                      </span>
                    </Row>
                  </Col>
                  {/* ------------------------------------------ BELLMEN RATIO ------------------------------------ */}
                  <Col xs={4}>
                    <Row className="position-relative circle-container mx-auto">
                      <Circle
                        percent={
                          calculateBellmanStats(staff).percentageOfBellmen
                        }
                        strokeWidth={10}
                        trailWidth={8}
                        strokeColor={"#2c3e50"}
                      />
                      <div className="circle-text">
                        <span className="text-primary">
                          {calculateBellmanStats(staff).percentageOfBellmen}%
                        </span>
                      </div>
                    </Row>
                    <Row>
                      <span className="text-primary text-center">
                        Bellmen
                        {calculateBellmanStats(staff).totalBellmen}
                      </span>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="pt-4 justify-content-between align-items-center">
            <Col xs={7}>
              <hr />
              <StaffStats
                title={"Attendants"}
                active={calculateMaidStats(staff).activeMaids}
                inActive={calculateMaidStats(staff).inactiveMaids}
              />
              <hr />
              <StaffStats
                title={"Valets"}
                active={calculateValetStats(staff).activeValets}
                inActive={calculateValetStats(staff).inactiveValets}
              />
              <hr />
              <StaffStats
                title={"Bellmen"}
                active={calculateBellmanStats(staff).activeBellmen}
                inActive={calculateBellmanStats(staff).inactiveBellmen}
              />
              <hr />
            </Col>
            {valetTeamsLoading ? (
              <Col xs={4}>
                <Row>
                  <Loader />
                  <h6 className="text-center my-1 fw-normal">
                    Loding Teams Data
                  </h6>
                </Row>
              </Col>
            ) : (
              <Col xs={4}>
                <h5 className="text-center">Valet Pool Tips</h5>
                {doughnutData && <Doughnut data={doughnutData} />}
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Stats;
