import React from "react";
import { Col, Row } from "react-bootstrap";

const TotalSum = ({ title, amount, percentage }) => {
  return (
    <Col
      xs={3}
      className="border border-1 border-secondary border-opacity-25  p-4"
    >
      <Row>
        <Col xs="auto" className="text-muted">
          {title}
        </Col>
        {/* {percentage && (
          <Col xs="auto" className="ms-auto">
            {`${percentage}%`}
          </Col>
        )} */}
      </Row>
      <h3 className="fw-normal mt-3">${amount}</h3>
    </Col>
  );
};

export default TotalSum;
