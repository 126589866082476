export const STAFF_GET_ALL_REQUEST = "STAFF_GET_ALL_REQUEST";
export const STAFF_GET_ALL_SUCCESS = "STAFF_GET_ALL_SUCCESS";
export const STAFF_GET_ALL_FAIL = "STAFF_GET_ALL_FAIL";

export const MAID_GET_ALL_REQUEST = "MAID_GET_ALL_REQUEST";
export const MAID_GET_ALL_SUCCESS = "MAID_GET_ALL_SUCCESS";
export const MAID_GET_ALL_FAIL = "MAID_GET_ALL_FAIL";

export const MAID_CREATE_REQUEST = "MAID_CREATE_REQUEST";
export const MAID_CREATE_SUCCESS = "MAID_CREATE_SUCCESS";
export const MAID_CREATE_FAIL = "MAID_CREATE_FAIL";
export const MAID_CREATE_RESET = "MAID_CREATE_RESET";

export const MAID_GET_REQUEST = "MAID_GET_REQUEST";
export const MAID_GET_SUCCESS = "MAID_GET_SUCCESS";
export const MAID_GET_FAIL = "MAID_GET_FAIL";

export const MAID_DELETE_REQUEST = "MAID_DELETE_REQUEST";
export const MAID_DELETE_SUCCESS = "MAID_DELETE_SUCCESS";
export const MAID_DELETE_FAIL = "MAID_DELETE_FAIL";

export const MAID_ADD_TIP_REQUEST = "MAID_ADD_TIP_REQUEST";
export const MAID_ADD_TIP_SUCCESS = "MAID_ADD_TIP_SUCCESS";
export const MAID_ADD_TIP_FAIL = "MAID_ADD_TIP_FAIL";

export const MAID_GET_TIPS_REQUEST = "MAID_GET_TIPS_REQUEST";
export const MAID_GET_TIPS_SUCCESS = "MAID_GET_TIPS_SUCCESS";
export const MAID_GET_TIPS_FAIL = "MAID_GET_TIPS_FAIL";

export const TIP_ADD_REQUEST = "TIP_ADD_REQUEST";
export const TIP_ADD_SUCCESS = "TIP_ADD_SUCCESS";
export const TIP_ADD_FAIL = "TIP_ADD_FAIL";

export const MAID_GET_EARNINGS_REQUEST = "MAID_GET_EARNINGS_REQUEST";
export const MAID_GET_EARNINGS_SUCCESS = "MAID_GET_EARNINGS_SUCCESS";
export const MAID_GET_EARNINGS_FAIL = "MAID_GET_EARNINGS_FAIL";

export const PAY_MAID_REQUEST = "PAY_MAID_REQUEST";
export const PAY_MAID_SUCCESS = "PAY_MAID_SUCCESS";
export const PAY_MAID_FAIL = "PAY_MAID_FAIL";

export const MAID_UPDATE_REQUEST = "MAID_UPDATE_REQUEST";
export const MAID_UPDATE_SUCCESS = "MAID_UPDATE_SUCCESS";
export const MAID_UPDATE_FAIL = "MAID_UPDATE_FAIL";
export const MAID_UPDATE_RESET = "MAID_UPDATE_RESET";

export const ADD_RATING_SUCCESS = "ADD_RATING_SUCCESS";
export const ADD_RATING_FAIL = "ADD_RATING_FAIL";

export const VALET_TEAM_CREATE_REQUEST = "VALET_TEAM_CREATE_REQUEST";
export const VALET_TEAM_CREATE_SUCCESS = "VALET_TEAM_CREATE_SUCCESS";
export const VALET_TEAM_CREATE_FAIL = "VALET_TEAM_CREATE_FAIL";
export const VALET_TEAM_CREATE_RESET = "VALET_TEAM_CREATE_RESET";

export const GET_ALL_VALET_TEAMS_REQUEST = "GET_ALL_VALET_TEAMS_REQUEST";
export const GET_ALL_VALET_TEAMS_SUCCESS = "GET_ALL_VALET_TEAMS_SUCCESS";
export const GET_ALL_VALET_TEAMS_FAIL = "GET_ALL_VALET_TEAMS_FAIL";

export const GET_VALET_TEAMS_REQUEST = "GET_VALET_TEAMS_REQUEST";
export const GET_VALET_TEAMS_SUCCESS = "GET_VALET_TEAMS_SUCCESS";
export const GET_VALET_TEAMS_FAIL = "GET_VALET_TEAMS_FAIL";

export const ADD_TEAM_MEMBER_REQUEST = "ADD_TEAM_MEMBER_REQUEST";
export const ADD_TEAM_MEMBER_SUCCESS = "ADD_TEAM_MEMBER_SUCCESS";
export const ADD_TEAM_MEMBER_FAIL = "ADD_TEAM_MEMBER_FAIL";
export const ADD_TEAM_MEMBER_RESET = "ADD_TEAM_MEMBER_RESET";

export const REMOVE_TEAM_MEMBER_REQUEST = "REMOVE_TEAM_MEMBER_REQUEST";
export const REMOVE_TEAM_MEMBER_SUCCESS = "REMOVE_TEAM_MEMBER_SUCCESS";
export const REMOVE_TEAM_MEMBER_FAIL = "REMOVE_TEAM_MEMBER_FAIL";
export const REMOVE_TEAM_MEMBER_RESET = "REMOVE_TEAM_MEMBER_RESET";

export const DELETE_TEAM_MEMBER_REQUEST = "DELETE_TEAM_MEMBER_REQUEST";
export const DELETE_TEAM_MEMBER_SUCCESS = "DELETE_TEAM_MEMBER_SUCCESS";
export const DELETE_TEAM_MEMBER_FAIL = "DELETE_TEAM_MEMBER_FAIL";
export const DELETE_TEAM_MEMBER_RESET = "DELETE_TEAM_MEMBER_RESET";

export const VERIFY_PAYMENT_REQUEST = "VERIFY_PAYMENT_REQUEST";
export const VERIFY_PAYMENT_SUCCESS = "VERIFY_PAYMENT_SUCCESS";
export const VERIFY_PAYMENT_FAIL = "VERIFY_PAYMENT_FAIL";
export const RESET_PAYMENT_STATUS = "RESET_PAYMENT_STATUS";
