import {
  faArrowsRotate,
  faCheck,
  faCircleExclamation,
  faDownload,
  faFileArrowDown,
  faMagnifyingGlass,
  faRotateLeft,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGooglePay,
  faApplePay,
  faCcApplePay,
  faApple,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCreditCard,
} from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
// import { format } from "date-fns";
import React, { useState, useEffect } from "react";
import {
  Col,
  FloatingLabel,
  Row,
  Table,
  Form,
  Dropdown,
  DropdownButton,
  Button,
} from "react-bootstrap";
import * as XLSX from "xlsx";
import { getTransactions } from "../redux/Actions/paymentAction";
import Loader from "../components/Loader";

const SuccessfullPyamentsNew = () => {
  const [filteredPayments, setFilteredPayments] = useState();
  const [filterError, setFilterError] = useState(false);
  const [mergedTransactionsInfo, setMergedTransactionsInfo] = useState([]);
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
    transactionId: "",
    clientName: "",
    type: "",
    status: "",
  });
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  const { payments } = useSelector((state) => state.allPayments);
  const transactionsList = useSelector((state) => state.transactionsList);
  const { loading, error, transactions } = transactionsList;

  const successFullTransactions = payments.filter(
    (payment) => payment.teamTip !== true
  );
  const paymentIntentIds = successFullTransactions.map(
    (payment) => payment.paymentIntentId
  );
  //   console.log("Payment intent ids: ", paymentIntentIds);

  const dispatch = useDispatch();
  const handleFilterChange = (fieldName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [fieldName]: value,
    }));
  };
  const filterPayments = () => {
    const filteredResults = mergedTransactionsInfo.filter((payment) => {
      const paymentDate = new Date(payment.date);
      const startDateMatch =
        !filters.startDate || paymentDate >= new Date(filters.startDate);
      const endDateMatch =
        !filters.endDate || paymentDate <= new Date(filters.endDate);

      const transactionIdMatch =
        !filters.transactionId ||
        payment.paymentIntentId.includes(
          filters.transactionId.replace(/\s/g, "")
        );

      const clientNameMatch =
        !filters.clientName ||
        (payment.clientName &&
          payment.clientName
            .toLowerCase()
            .replace(/\s/g, "")
            .includes(filters.clientName.toLowerCase().replace(/\s/g, "")));

      const typeMatch =
        !filters.type ||
        (payment.sentTo === "Team tip"
          ? "Direct Transfer"
          : "Destination Charge") === filters.type;

      const statusMatch =
        !filters.status ||
        (filters.status === "Refunded" && payment.refunded) ||
        (filters.status === "Disputed" && payment.disputed) ||
        (filters.status === "Success" &&
          !payment.refunded &&
          !payment.disputed);

      return (
        startDateMatch &&
        endDateMatch &&
        transactionIdMatch &&
        clientNameMatch &&
        typeMatch &&
        statusMatch
      );
    });
    if (filteredResults.length === 0) {
      alert("No result found with filters applied");
      // setFilterError(true);
    } else {
      // setFilterError(false);
      setFilteredPayments(filteredResults);
    }
    // .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  };
  const clearFilters = () => {
    setFilters({
      startDate: "",
      endDate: "",
      transactionId: "",
      clientName: "",
      type: "",
      status: "",
    });
    setFilteredPayments(null);
  };
  const handleDownloadReceipt = (transactionData) => {
    const transactionDataString = encodeURIComponent(
      JSON.stringify(transactionData)
    );
    const url = `/receipt?data=${transactionDataString}`;
    const newWindow = window.open(url, "_blank");
    if (!newWindow) {
      console.error(
        "Failed to open a new window. Ensure that pop-ups are allowed."
      );
    }
  };
  const handleDownloadReports = () => {
    const table = document.querySelector(".reportNew");
    const data = [];
    const headers = [];
    table.querySelectorAll("thead th").forEach((th) => {
      if (th.textContent.trim() !== "Receipt") {
        headers.push(th.textContent.trim());
      }
    });
    data.push(headers);
    table.querySelectorAll("tbody tr").forEach((row) => {
      const rowData = [];
      row.querySelectorAll("td").forEach((cell, index) => {
        if (index !== row.cells.length - 1) {
          // Exclude the "Receipt" column
          if (index >= 6 && index <= 12) {
            // Columns 5, 6, 7, 8 are money amounts
            rowData.push(
              parseFloat(cell.textContent.trim().replace("$", "")) || 0
            );
          } else {
            rowData.push(cell.textContent.trim());
          }
        }
      });
      data.push(rowData);
    });
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");
    XLSX.writeFile(wb, "tipmaid-transactions-report.xlsx");
  };

  useEffect(() => {
    console.log("Use effect was fired");
    dispatch(getTransactions());
  }, [dispatch]);

  useEffect(() => {
    if (transactions.length > 0 && paymentIntentIds.length > 0) {
      console.log("If statement in use effect was true");
      const mergedTransactionsInfo = successFullTransactions.map((payment) => {
        const matchingTransaction = transactions.find(
          (transaction) => transaction.payment_intent == payment.paymentIntentId
        );
        return {
          paymentIntentId: payment.paymentIntentId,
          date: payment.createdAt,
          clientName: payment.clientName,
          sentTo:
            payment.connectAccount != "Team Payment"
              ? payment.connectAccount
              : "Team tip",
          tipAmount: payment.tipAmount,
          ...(payment.commissionCharged && {
            commissionCharged: 0.1,
            partnerId: payment.partnerId,
          }),

          totalAmount: payment.amount,
          processingFee: payment.commissionCharged
            ? parseFloat(payment.processingFee - 0.1).toFixed(2)
            : payment.processingFee,
          stripeFee: payment.stripeFee,
          paymentMethod: matchingTransaction?.payment_method_details.card.wallet
            ? matchingTransaction.payment_method_details.card.wallet.type
            : "Card Payment",
          disputed: matchingTransaction?.disputed,
          refunded: matchingTransaction?.refunded,
        };
      });
      setMergedTransactionsInfo(mergedTransactionsInfo);
      //   setFilteredPayments(mergedTransactions);
    }
  }, [loading]);
  const styles = {
    shadow: {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    },
    shadow2: {
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
  };
  return (
    <>
      <Row className="mt-4">
        <Col className="p-0">
          {/* ------------------------------------------- FILTERS -------------------------------------------------------- */}
          <Row className="mb-4 align-items-center justify-content-center">
            <Col xs={2}>
              <FloatingLabel label="Start Date">
                <Form.Control
                  style={styles.shadow2}
                  className="border-0 rounded-4"
                  type="date"
                  placeholder="Start Date"
                  value={filters.startDate}
                  onChange={(e) =>
                    handleFilterChange("startDate", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col xs={2}>
              <FloatingLabel label="End Date">
                <Form.Control
                  style={styles.shadow2}
                  className="border-0 rounded-4"
                  type="date"
                  placeholder="End Date"
                  value={filters.endDate}
                  onChange={(e) =>
                    handleFilterChange("endDate", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col xs={2}>
              <FloatingLabel label="Transaction Id">
                <Form.Control
                  style={styles.shadow2}
                  className="border-0 rounded-4"
                  type="text"
                  placeholder="Transaction Id"
                  value={filters.transactionId}
                  onChange={(e) =>
                    handleFilterChange("transactionId", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col xs={1}>
              <Form.Select
                style={styles.shadow2}
                className="border-0 rounded-4 py-3"
                value={filters.status}
                onChange={(e) => handleFilterChange("status", e.target.value)}
              >
                <option value="">Status</option>
                <option value="Success">Success</option>
                <option value="Refunded">Refunded</option>
                <option value="Disputed">Disputed</option>
              </Form.Select>
            </Col>

            <Col xs={2}>
              <FloatingLabel label="Client Name">
                <Form.Control
                  style={styles.shadow2}
                  className="border-0 rounded-4"
                  type="text"
                  placeholder="Client Name"
                  value={filters.clientName}
                  onChange={(e) =>
                    handleFilterChange("clientName", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col xs={1}>
              <Form.Select
                style={styles.shadow2}
                className="border-0 rounded-4 py-3"
                value={filters.type}
                onChange={(e) => handleFilterChange("type", e.target.value)}
              >
                <option>Type</option>
                <option value="Direct Transfer">Direct Transfer</option>
                <option value="Destination Charge">Destination Charge</option>
              </Form.Select>
            </Col>
            <Col xs="auto" className="">
              <Button
                variant="secondary"
                onClick={clearFilters}
                className=" rounded-3"
              >
                <FontAwesomeIcon icon={faRotateLeft} />
              </Button>
              <Button
                variant="success"
                className="mx-2"
                onClick={handleDownloadReports}
              >
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <Button
                className="rounded-3"
                variant="primary"
                onClick={() => filterPayments()}
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} className="" />
              </Button>
            </Col>
          </Row>
          {/* -------------------------------------------- LIST OF TRANSACTIONS -------------------------------------------- */}
          <Row className="m-0">
            <Col>
              {loading ? (
                <Row className="vh-90 justify-content-center align-items-center">
                  <Loader />
                </Row>
              ) : (
                <div className="">
                  {mergedTransactionsInfo &&
                    (filteredPayments && filteredPayments.length > 0
                      ? filteredPayments
                      : mergedTransactionsInfo
                    )
                      .slice()
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .map((transaction, index) => (
                        <div
                          key={index}
                          className="p-3 rounded-4 border-0 my-3"
                          style={styles.shadow2}
                        >
                          <Row className="align-items-center ">
                            <Col md={10}>
                              <h5 className="m-0">
                                <div className="text-muted me-1 d-inline">
                                  {index + 1}.
                                </div>
                                {transaction.clientName}
                              </h5>
                              <span className="text-muted">
                                {transaction.paymentIntentId}
                              </span>
                              <Row className="mt-2">
                                <Col md={4} className="">
                                  <div className="d-inline fw-semibold">
                                    Date:
                                  </div>
                                  <div className="text-muted d-inline ms-1">
                                    {new Date(
                                      transaction.date
                                    ).toLocaleDateString("en-US", options)}
                                  </div>
                                </Col>
                                <Col md={4} className="">
                                  <div className="d-inline fw-semibold">
                                    Sent to:
                                  </div>
                                  {transaction.sentTo == "Team tip" ? (
                                    <div className="text-info d-inline ms-1">
                                      Team tip
                                    </div>
                                  ) : (
                                    <div className="text-muted fst-italic d-inline ms-1">
                                      {transaction.sentTo}
                                    </div>
                                  )}
                                </Col>
                                <Col md={4} className="">
                                  <div className="d-inline fw-semibold">
                                    Payment method:
                                  </div>
                                  {transaction.paymentMethod == "apple_pay" ? (
                                    <span>
                                      <FontAwesomeIcon
                                        icon={faApple}
                                        className="mx-1"
                                      />
                                      Pay
                                    </span>
                                  ) : transaction.paymentMethod ==
                                    "google_pay" ? (
                                    <span>
                                      <FontAwesomeIcon
                                        icon={faGoogle}
                                        className="mx-1"
                                      />
                                      Pay
                                    </span>
                                  ) : (
                                    <span>
                                      <FontAwesomeIcon
                                        icon={faCreditCard}
                                        className="mx-1"
                                      />
                                      {transaction.paymentMethod}
                                    </span>
                                  )}
                                </Col>
                              </Row>
                              <Row className="">
                                <Col md={4}>
                                  <div className="d-inline fw-semibold">
                                    Stripe fee :
                                  </div>
                                  <div className="text-muted d-inline ms-2">
                                    ${transaction.stripeFee}
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="d-inline fw-semibold">
                                    Commission charged:
                                  </div>
                                  <div className="text-muted d-inline ms-2">
                                    {transaction.commissionCharged
                                      ? `$${transaction.commissionCharged}`
                                      : "None"}
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className="d-inline fw-semibold">
                                    Tip breakdown:
                                  </div>
                                  <div className="text-muted d-inline ms-2">
                                    ${transaction.tipAmount}+$1+
                                    {parseFloat(
                                      transaction.processingFee - 1
                                    ).toFixed(2)}
                                    {transaction.commissionCharged
                                      ? "+$0.1"
                                      : null}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="ms-auto text-center">
                              <span
                                className={
                                  transaction.refunded
                                    ? "fw-semibold fs-4 text-danger"
                                    : transaction.disputed
                                    ? "fw-semibold fs-4 text-danger"
                                    : "fw-semibold fs-4 text-info"
                                }
                              >
                                {transaction.refunded
                                  ? "-"
                                  : transaction.disputed
                                  ? "-"
                                  : null}
                                ${transaction.totalAmount}
                              </span>
                              <span className="d-block text-center">
                                {transaction.refunded ? (
                                  <div className="text-info d-inline ms-2">
                                    <FontAwesomeIcon icon={faArrowsRotate} />{" "}
                                    Refunded
                                  </div>
                                ) : transaction.disputed ? (
                                  <div className="text-warning d-inline ms-2">
                                    <FontAwesomeIcon
                                      icon={faCircleExclamation}
                                    />{" "}
                                    Disputed
                                  </div>
                                ) : (
                                  <div className="text-success d-inline ms-2">
                                    <FontAwesomeIcon icon={faCircleCheck} />{" "}
                                    Success
                                  </div>
                                )}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      ))}
                </div>
              )}
            </Col>
          </Row>
          {/* -------------------------------------------- TABLE --------------------------------------------------------- */}
          <Table striped bordered hover className="reportNew d-none">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Transaction id</th>
                <th>Status</th>
                <th>Sent To</th>
                <th>Client Name</th>
                <th>Tip</th>
                <th>+ Merchant Fee</th>
                <th>Processing fee</th>
                <th>Commission charged</th>
                <th>Total</th>
                <th>Stripe Fee</th>
                <th>Receipt</th>
              </tr>
            </thead>
            <tbody>
              {mergedTransactionsInfo &&
                (filteredPayments && filteredPayments.length > 0
                  ? filteredPayments
                  : mergedTransactionsInfo
                )
                  .slice()
                  .sort((a, b) => new Date(b.date) - new Date(a.date))
                  .map((payment, index) => (
                    <tr key={index}>
                      <td className="fw-bold">{index + 1}</td>
                      <td>
                        {new Date(payment.date).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>
                      <td className="text-muted fst-italic">
                        {payment.paymentIntentId}
                      </td>
                      <td>
                        {payment.refunded
                          ? "Refunded"
                          : payment.disputed
                          ? "Disputed"
                          : "Success"}
                      </td>
                      <td>{payment.sentTo}</td>
                      <td>{payment.clientName}</td>
                      <td>{payment.tipAmount}</td>
                      <td className="text-center">${payment.tipAmount + 1}</td>
                      <td className="text-center">${payment.processingFee}</td>
                      <td className="text-center">
                        {payment.commissionCharged}
                      </td>
                      <td>
                        {payment.refunded
                          ? -payment.totalAmount
                          : payment.disputed
                          ? -payment.totalAmount
                          : payment.totalAmount}
                      </td>
                      <td className="text-center">${payment.stripeFee}</td>
                      <td className="text-center">
                        <div onClick={() => handleDownloadReceipt(payment)}>
                          <FontAwesomeIcon
                            className="fs-5 text-success"
                            icon={faFileArrowDown}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default SuccessfullPyamentsNew;
