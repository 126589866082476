export const calculateTotalSum = (transactions) => {
  const sum = transactions.reduce((total, transaction) => {
    return total + (transaction.totalAmount || 0);
  }, 0);

  return parseFloat(sum.toFixed(2));
};

export const calculateTotalSubcharged = (transactions) => {
  const subchargedSum = transactions.reduce((total, transaction) => {
    const subchargedAmount =
      (transaction.totalAmount || 0) - (transaction.tipAmount || 0);
    return total + subchargedAmount;
  }, 0);

  return parseFloat(subchargedSum.toFixed(2));
};

export const calculateTotalStripeFee = (transactions) => {
  const totalStripeFee = transactions.reduce((total, transaction) => {
    return total + (parseFloat(transaction.stripeFee) || 0);
  }, 0);

  return parseFloat(totalStripeFee.toFixed(2));
};

export const calculateTotalCommissionCharged = (transactions) => {
  const totalCommission = transactions.reduce((total, transaction) => {
    return total + (transaction.commissionCharged || 0);
  }, 0);

  return parseFloat(totalCommission.toFixed(2));
};

// Function to get monthly data
// export const getMonthlyData = (transactions) => {
//   const months = Array(12).fill(0); // Initialize array with 12 months, all set to 0
//   transactions.forEach((transaction) => {
//     const month = new Date(transaction.date).getMonth(); // Extract month from the date
//     months[month] += 1; // Increment the transaction count for the month
//   });
//   return months;
// };

// // Function to get weekly data
// export const getWeeklyData = (transactions) => {
//   const weeks = Array(4).fill(0); // Initialize array with 4 weeks, all set to 0
//   transactions.forEach((transaction) => {
//     const week = Math.floor(new Date(transaction.date).getDate() / 7); // Calculate the week of the month
//     weeks[week] += 1; // Increment the transaction count for the week
//   });
//   return weeks;
// };

// Function to get monthly data
export const getMonthlyData = (transactions) => {
  if (!Array.isArray(transactions)) {
    return Array(12).fill(0); // Return a default array if transactions is undefined or not an array
  }

  const months = Array(12).fill(0); // Initialize array with 12 months, all set to 0
  transactions.forEach((transaction) => {
    const month = new Date(transaction.date).getMonth(); // Extract month from the date
    months[month] += 1; // Increment the transaction count for the month
  });
  return months;
};

// Function to get weekly data
// export const getWeeklyData = (transactions) => {
//   const weeklyData = Array(4)
//     .fill(0)
//     .map(() => Array(7).fill(0)); // 4 weeks, 7 days each

//   transactions.forEach((transaction) => {
//     const transactionDate = new Date(transaction.date);
//     const dayOfWeek = transactionDate.getUTCDay(); // 0 = Sunday, 6 = Saturday
//     const weekIndex = Math.floor(transactionDate.getUTCDate() / 7); // Determine the week (0 to 3)

//     if (weekIndex < 4) {
//       weeklyData[weekIndex][dayOfWeek] += 1; // Increment the count for the specific day of the week
//     }
//   });

//   return weeklyData; // Return the 2D array for weeks and days
// };

// export const getWeeklyData = (transactions) => {
//   const weeklyData = {};

//   // Get the current month and year
//   const now = new Date();
//   const currentMonth = now.getMonth(); // 0 = January, 1 = February, ...
//   const currentYear = now.getFullYear();

//   transactions.forEach((transaction) => {
//     const transactionDate = new Date(transaction.date);

//     // Check if the transaction is from the current month and year
//     if (
//       transactionDate.getMonth() === currentMonth &&
//       transactionDate.getFullYear() === currentYear
//     ) {
//       const weekNumber = Math.ceil(transactionDate.getUTCDate() / 7); // Calculate the week number
//       const dayOfWeek = transactionDate.getUTCDay(); // Get the day of the week

//       // Initialize the week object if it doesn't exist
//       if (!weeklyData[weekNumber]) {
//         weeklyData[weekNumber] = Array(7).fill(0); // Initialize with zeros for each day
//       }

//       // Increment the count for the specific day of the week
//       weeklyData[weekNumber][dayOfWeek] += 1;
//     }
//   });

//   // Convert the weeklyData object to an array for the current month's weeks
//   const weeklyCounts = [];
//   for (let i = 1; i <= 4; i++) {
//     weeklyCounts[i - 1] = weeklyData[i] || Array(7).fill(0); // Fill with zeros if the week doesn't exist
//   }

//   return weeklyCounts; // Return the array for weeks and days
// };

export const getWeeklyData = (transactions, targetMonth, targetYear) => {
  const weeklyData = {};
  transactions.forEach((transaction) => {
    const transactionDate = new Date(transaction.date);
    if (
      transactionDate.getMonth() === targetMonth &&
      transactionDate.getFullYear() === targetYear
    ) {
      const dayOfMonth = transactionDate.getDate();
      const weekNumber = Math.ceil(dayOfMonth / 7);
      if (!weeklyData[weekNumber]) {
        weeklyData[weekNumber] = {};
      }
      if (!weeklyData[weekNumber][dayOfMonth]) {
        weeklyData[weekNumber][dayOfMonth] = 0;
      }
      weeklyData[weekNumber][dayOfMonth] += 1;
    }
  });

  // Generate a data array for Chart.js
  const weeklyCounts = [];
  const labels = Array.from({ length: 31 }, (_, i) => i + 1); // Days of the month (1-31)

  for (let week = 1; week <= 5; week++) {
    const weekData = labels.map((day) =>
      weeklyData[week] && weeklyData[week][day] ? weeklyData[week][day] : 0
    );
    weeklyCounts.push(weekData);
  }
  return {
    labels,
    datasets: weeklyCounts.map((data, index) => ({
      label: `Week ${index + 1}`,
      data,
      fill: false,
      borderColor: `rgba(75, 192, 192, ${0.5 + index * 0.1})`,
      tension: 0.1,
    })),
  };
};

export const calculateTeamTipStats = (transactions) => {
  const teamTipTransactions = transactions.filter(
    (transaction) => transaction.sentTo === "Team tip"
  );
  const numberOfTeamTips = teamTipTransactions.length;
  const percentageOfTeamTips =
    numberOfTeamTips > 0
      ? Math.floor((numberOfTeamTips / transactions.length) * 100)
      : 0;
  return {
    numberOfTeamTipTransactions: numberOfTeamTips,
    percentageOfTeamTips: percentageOfTeamTips,
  };
};

export const calculateIndividualTipStats = (transactions) => {
  const individualTipTransactions = transactions.filter(
    (transaction) => transaction.sentTo !== "Team tip"
  );
  const numberOfIndividualTips = individualTipTransactions.length;
  const percentageOfIndividualTips =
    numberOfIndividualTips > 0
      ? Math.floor((numberOfIndividualTips / transactions.length) * 100)
      : 0;

  return {
    numberOfIndividualTipTransactions: numberOfIndividualTips,
    percentageOfIndividualTips: percentageOfIndividualTips,
  };
};

export const calculateRefundedTransactionStats = (transactions) => {
  const refundedTransactions = transactions.filter(
    (transaction) => transaction.refunded === true
  );
  const numberOfRefundedTransactions = refundedTransactions.length;
  const percentageOfRefundedTransactions =
    numberOfRefundedTransactions > 0
      ? Math.floor((numberOfRefundedTransactions / transactions.length) * 100)
      : 0;

  return {
    numberOfRefundedTransactions: numberOfRefundedTransactions,
    percentageOfRefundedTransactions: percentageOfRefundedTransactions,
  };
};

export const calculateMaidStats = (staffMembers) => {
  const maidMembers = staffMembers.filter((staff) => staff.type === "maid");
  const totalMaids = maidMembers.length;
  const totalStaff = staffMembers.length;

  const activeMaids = maidMembers.filter(
    (staff) => staff.chargesStatus === true
  ).length;
  const inactiveMaids = totalMaids - activeMaids;

  const percentageOfMaids =
    totalStaff > 0 ? Math.floor((totalMaids / totalStaff) * 100) : 0;

  return {
    totalMaids: totalMaids,
    percentageOfMaids: percentageOfMaids,
    activeMaids: activeMaids,
    inactiveMaids: inactiveMaids,
  };
};

export const calculateValetStats = (staffMembers) => {
  const valetMembers = staffMembers.filter((staff) => staff.type === "valet");
  const totalValets = valetMembers.length;
  const totalStaff = staffMembers.length;

  const activeValets = valetMembers.filter(
    (staff) => staff.chargesStatus === true
  ).length;
  const inactiveValets = totalValets - activeValets;

  const percentageOfValets =
    totalStaff > 0 ? Math.floor((totalValets / totalStaff) * 100) : 0;

  return {
    totalValets: totalValets,
    percentageOfValets: percentageOfValets,
    activeValets: activeValets,
    inactiveValets: inactiveValets,
  };
};

export const calculateBellmanStats = (staffMembers) => {
  const bellmanMembers = staffMembers.filter(
    (staff) => staff.type === "bellman"
  );
  const totalBellmen = bellmanMembers.length;
  const totalStaff = staffMembers.length;

  const activeBellmen = bellmanMembers.filter(
    (staff) => staff.chargesStatus === true
  ).length;
  const inactiveBellmen = totalBellmen - activeBellmen;

  const percentageOfBellmen =
    totalStaff > 0 ? Math.floor((totalBellmen / totalStaff) * 100) : 0;

  return {
    totalBellmen: totalBellmen,
    percentageOfBellmen: percentageOfBellmen,
    activeBellmen: activeBellmen,
    inactiveBellmen: inactiveBellmen,
  };
};

// Function to filter verified team tips
export const getVerifiedTeamTips = (valetTeams) => {
  return valetTeams.filter((team) => team.verified === true).length;
};

// Function to filter unverified team tips
export const getUnverifiedTeamTips = (valetTeams) => {
  return valetTeams.filter((team) => team.verified === false).length;
};
