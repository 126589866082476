import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  FloatingLabel,
  FormControl,
  Row,
} from "react-bootstrap";
import BackButton from "../components/BackButton";
import ViewStaff from "../components/ViewStaff";
import { setActiveTab } from "../redux/Actions/tabAcions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faMagnifyingGlass,
  faRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaids, getValetTeams } from "../redux/Actions/maidAction";
import ViewVerifiedTeam from "../components/ViewVerifiedTeam";
import ViewUnverifiedTeam from "../components/ViewUnverifiedTeam";
import Loader from "../components/Loader";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const ViewClient = ({ client }) => {
  const [selectedTab, setSelectedTab] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [staff, setStaff] = useState([]);
  const options = { year: "numeric", month: "numeric", day: "numeric" };

  let hotelId;
  if (client) {
    hotelId = client._id;
  }
  const {
    loading,
    error,
    maids: allMaids,
  } = useSelector((state) => state.maidsList);
  const { valetTeams } = useSelector((state) => state.valetTeams);

  const dispatch = useDispatch();
  const handleTabChange = (tab) => {
    if (loading) return;
    setSelectedTab(tab);
  };
  const handleBackButton = () => {
    setSelectedTab("");
    setStaff([]);
    dispatch(setActiveTab("#dashboard"));
  };
  const handleFiletrClear = () => {
    setStartDate("");
    setEndDate("");
  };
  useEffect(() => {
    dispatch(getAllMaids(hotelId));
    dispatch(getValetTeams(hotelId));
    setSelectedTab("");
    setStaff([]);
  }, [dispatch, hotelId]);

  useEffect(() => {
    if (allMaids) {
      switch (selectedTab) {
        case "attendants":
          setStaff(allMaids.filter((maid) => maid.type === "maid"));
          break;
        case "bellman":
          setStaff(allMaids.filter((maid) => maid.type === "bellman"));
          break;
        case "valet":
          setStaff(allMaids.filter((maid) => maid.type === "valet"));
          break;
        default:
          setStaff([]);
          break;
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!loading && allMaids) {
      setSelectedTab("attendants");
    }
  }, [loading, allMaids]);
  return (
    <>
      {staff && console.log("Staff members in view client: ", staff)}
      <Row className="m-0 p-5">
        <Col>
          <Row className="mb-4 text-muted">
            <Col className="hover-effect" xs="auto" onClick={handleBackButton}>
              <FontAwesomeIcon icon={faChevronLeft} className=" me-2" />
              <span className="fw-semibold">Dashboard</span>
            </Col>
          </Row>
          {client && (
            <Row className="mb-4">
              <h4 className="fw-bold text-center mb-3 text-primary">
                {client.companyName}
              </h4>
            </Row>
          )}
          <Row>
            <Col
              className={`p-2 mx-2 text-center fw-semibold ${
                selectedTab === "attendants"
                  ? "border-bottom border-4 border-success text-success"
                  : "border-bottom border-4 text-muted"
              }`}
              onClick={() => handleTabChange("attendants")}
              style={{ cursor: "pointer" }}
            >
              Attendants
            </Col>
            <Col
              className={`p-2 mx-2 text-center fw-semibold ${
                selectedTab === "bellman"
                  ? "border-bottom border-4 border-success text-success"
                  : "border-bottom border-4 text-muted"
              }`}
              onClick={() => handleTabChange("bellman")}
              style={{ cursor: "pointer" }}
            >
              Bellman
            </Col>
            <Col
              className={`p-2 mx-2 text-center fw-semibold ${
                selectedTab === "valet"
                  ? "border-bottom border-4 border-success text-success"
                  : "border-bottom border-4 text-muted"
              }`}
              onClick={() => handleTabChange("valet")}
              style={{ cursor: "pointer" }}
            >
              Valet
            </Col>
            <Col
              className={`p-2 mx-2 text-center fw-semibold ${
                selectedTab === "teamTipsVerified"
                  ? "border-bottom border-4 border-success text-success"
                  : "border-bottom border-4 text-muted"
              }`}
              onClick={() => handleTabChange("teamTipsVerified")}
              style={{ cursor: "pointer" }}
            >
              Team tips (verified)
            </Col>
            <Col
              className={`p-2 mx-2 text-center fw-semibold ${
                selectedTab === "teamTipsUnverified"
                  ? "border-bottom border-4 border-success text-success"
                  : "border-bottom border-4 text-muted"
              }`}
              onClick={() => handleTabChange("teamTipsUnverified")}
              style={{ cursor: "pointer" }}
            >
              Team tips (unverified)
            </Col>
          </Row>
          {loading ? (
            <Row className="mt-5">
              <Loader />
            </Row>
          ) : null}
          {!loading && (
            <Row className="my-4 justify-content-end align-items-center">
              <Col xs="auto" className="bg-light px-4 py-2 rounded-5">
                <Row>
                  <Col xs="auto">
                    <FormControl
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="bg-light border-0 py-0"
                    />
                  </Col>
                  <Col xs="auto" className="p-0 fw-semibold">
                    to
                  </Col>
                  <Col xs="auto">
                    <FormControl
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="bg-light border-0 py-0"
                    />
                  </Col>
                  {/* <Col xs="auto" className="p-0">
                  <Button
                    variant="outline-secondary"
                    className="border-0 py-0"
                    // onClick={handleFilter}
                  >
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      className="fs-6"
                    />
                  </Button>
                </Col> */}
                  <Col xs="auto" className="p-0">
                    <Button
                      variant="outline-secondary"
                      className="border-0 py-0"
                      onClick={handleFiletrClear}
                    >
                      <FontAwesomeIcon icon={faRotateLeft} className="fs-6" />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="mt-3">
            <Col>
              {selectedTab === "attendants" && (
                <ViewStaff
                  staff={staff}
                  type={"maid"}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
              {selectedTab === "bellman" && (
                <ViewStaff
                  staff={staff}
                  type={"bellmen"}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
              {selectedTab === "valet" && (
                <ViewStaff
                  staff={staff}
                  type={"valets"}
                  startDate={startDate}
                  endDate={endDate}
                />
              )}
              {selectedTab === "teamTipsVerified" && (
                <ViewVerifiedTeam teamTips={valetTeams && valetTeams} />
              )}
              {selectedTab === "teamTipsUnverified" && (
                <ViewUnverifiedTeam teamTips={valetTeams && valetTeams} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ViewClient;
