import axios from "axios";
import {
  ADD_RATING_FAIL,
  ADD_RATING_SUCCESS,
  ADD_TEAM_MEMBER_FAIL,
  ADD_TEAM_MEMBER_REQUEST,
  ADD_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAIL,
  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_RESET,
  DELETE_TEAM_MEMBER_SUCCESS,
  GET_ALL_VALET_TEAMS_FAIL,
  GET_ALL_VALET_TEAMS_REQUEST,
  GET_ALL_VALET_TEAMS_SUCCESS,
  GET_VALET_TEAMS_FAIL,
  GET_VALET_TEAMS_REQUEST,
  GET_VALET_TEAMS_SUCCESS,
  MAID_CREATE_FAIL,
  MAID_CREATE_REQUEST,
  MAID_CREATE_SUCCESS,
  MAID_DELETE_FAIL,
  MAID_DELETE_REQUEST,
  MAID_DELETE_SUCCESS,
  MAID_GET_ALL_FAIL,
  MAID_GET_ALL_REQUEST,
  MAID_GET_ALL_SUCCESS,
  MAID_GET_EARNINGS_FAIL,
  MAID_GET_EARNINGS_REQUEST,
  MAID_GET_EARNINGS_SUCCESS,
  MAID_UPDATE_FAIL,
  MAID_UPDATE_REQUEST,
  MAID_UPDATE_SUCCESS,
  PAY_MAID_FAIL,
  PAY_MAID_REQUEST,
  PAY_MAID_SUCCESS,
  REMOVE_TEAM_MEMBER_FAIL,
  REMOVE_TEAM_MEMBER_REQUEST,
  REMOVE_TEAM_MEMBER_SUCCESS,
  STAFF_GET_ALL_FAIL,
  STAFF_GET_ALL_REQUEST,
  STAFF_GET_ALL_SUCCESS,
  TIP_ADD_FAIL,
  TIP_ADD_REQUEST,
  TIP_ADD_SUCCESS,
  VALET_TEAM_CREATE_FAIL,
  VALET_TEAM_CREATE_REQUEST,
  VALET_TEAM_CREATE_SUCCESS,
  VERIFY_PAYMENT_FAIL,
  VERIFY_PAYMENT_REQUEST,
  VERIFY_PAYMENT_SUCCESS,
} from "../Constants/maidConstants";
import { BACKEND_URL } from "../../config.js";

export const getAllMaids = (hotelId) => async (dispatch) => {
  try {
    dispatch({ type: MAID_GET_ALL_REQUEST });
    const { data } = await axios.get(
      `${BACKEND_URL}api/maids?hotelId=${hotelId}`
    );
    dispatch({ type: MAID_GET_ALL_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: MAID_GET_ALL_FAIL, payload: errorMessage });
  }
};

export const getAllStaff = () => async (dispatch) => {
  try {
    dispatch({ type: STAFF_GET_ALL_REQUEST });
    const { data } = await axios.get(`${BACKEND_URL}api/maids/getAllStaff`);
    dispatch({ type: STAFF_GET_ALL_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: STAFF_GET_ALL_FAIL, payload: errorMessage });
  }
};

export const createMaid =
  (firstName, lastName, serviceName, type, email, employId, hotel) =>
  async (dispatch) => {
    try {
      dispatch({ type: MAID_CREATE_REQUEST });
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${BACKEND_URL}api/maids`,
        { firstName, lastName, serviceName, type, email, employId, hotel },
        config
      );
      dispatch({ type: MAID_CREATE_SUCCESS, payload: data });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({ type: MAID_CREATE_FAIL, payload: errorMessage });
    }
  };

export const deleteMaid = (maidId) => async (dispatch) => {
  try {
    dispatch({ type: MAID_DELETE_REQUEST });
    await axios.delete(`${BACKEND_URL}api/maids/${maidId}`);

    dispatch({ type: MAID_DELETE_SUCCESS, payload: maidId });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: MAID_DELETE_FAIL, payload: errorMessage });
  }
};

export const updateMaid = (id, firstName, lastName) => async (dispatch) => {
  try {
    dispatch({ type: MAID_UPDATE_REQUEST });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const { data } = await axios.put(
      `${BACKEND_URL}api/maids/update`,
      { id, firstName, lastName },
      config
    );
    dispatch({ type: MAID_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: MAID_UPDATE_FAIL, payload: errorMessage });
  }
};

export const addTip = (id, paymentId, amount, review) => async (dispatch) => {
  try {
    dispatch({ type: TIP_ADD_REQUEST });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${BACKEND_URL}api/maids/${id}/addTip`,
      { paymentId, amount, review },
      config
    );
    dispatch({ type: TIP_ADD_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: TIP_ADD_FAIL, payload: errorMessage });
  }
};

export const getMaidEarnings =
  (hotelId, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: MAID_GET_EARNINGS_REQUEST });

      const { data } = await axios.get(
        `${BACKEND_URL}api/maids/earnings?hotelId=${hotelId}&startDate=${startDate}&endDate=${endDate}`
      );
      dispatch({ type: MAID_GET_EARNINGS_SUCCESS, payload: data });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({ type: MAID_GET_EARNINGS_FAIL, payload: errorMessage });
    }
  };

export const payMaid =
  (maid, hotel, token, amount, teamTip, idempotencyKey) => async (dispatch) => {
    console.log("Payment redux action was called");
    try {
      dispatch({ type: PAY_MAID_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
          "Idempotency-Key": idempotencyKey,
        },
      };
      const response = await axios.post(
        `${BACKEND_URL}payment/payMaid`,
        {
          maid,
          hotel,
          token,
          amount,
          teamTip,
        },
        config
      );
      if (response.data.success) {
        dispatch({ type: PAY_MAID_SUCCESS, payload: response.data });
      } else {
        const errorMessage = response.data.message;
        dispatch({ type: PAY_MAID_FAIL, payload: errorMessage });
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.error
        : error.message;
      dispatch({ type: PAY_MAID_FAIL, payload: errorMessage });
    }
  };

export const addRating = (maidAccount, rating) => async (dispatch) => {
  try {
    const response = await axios.post(`${BACKEND_URL}api/maids/ratings`, {
      rating,
      maidAccount,
    });
    dispatch({ type: ADD_RATING_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: ADD_RATING_FAIL, payload: error.message });
  }
};

export const createValetTeam =
  (
    hotel,
    location,
    startDate,
    endDate,
    tip,
    review,
    teamMembers,
    paymentIntentId
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: VALET_TEAM_CREATE_REQUEST });
      const { data } = await axios.post(`${BACKEND_URL}api/maids/valet-teams`, {
        hotel,
        location,
        startDate,
        endDate,
        tip,
        review,
        teamMembers,
        paymentIntentId,
      });

      dispatch({ type: VALET_TEAM_CREATE_SUCCESS, payload: data });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({ type: VALET_TEAM_CREATE_FAIL, payload: errorMessage });
    }
  };

export const getAllValetTeams = () => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_VALET_TEAMS_REQUEST });

    const { data } = await axios.get(`${BACKEND_URL}api/maids/valet-teams`);

    dispatch({ type: GET_ALL_VALET_TEAMS_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: GET_ALL_VALET_TEAMS_FAIL, payload: errorMessage });
  }
};

export const getValetTeams = (hotelId) => async (dispatch) => {
  try {
    dispatch({ type: GET_VALET_TEAMS_REQUEST });

    const { data } = await axios.get(
      `${BACKEND_URL}api/maids/valet-teams/${hotelId}`
    );

    dispatch({ type: GET_VALET_TEAMS_SUCCESS, payload: data });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: GET_VALET_TEAMS_FAIL, payload: errorMessage });
  }
};

export const addTeamMember =
  (valetTeamId, teamMemberData) => async (dispatch) => {
    try {
      dispatch({ type: ADD_TEAM_MEMBER_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `${BACKEND_URL}api/maids/add-member`,
        { valetTeamId, teamMemberData },
        config
      );
      dispatch({ type: ADD_TEAM_MEMBER_SUCCESS, payload: data });
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      dispatch({ type: ADD_TEAM_MEMBER_FAIL, payload: errorMessage });
    }
  };

export const deleteTeamMember = (valetTeamId, memberId) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TEAM_MEMBER_REQUEST });
    const { data } = await axios.delete(
      `${BACKEND_URL}api/maids/${valetTeamId}/member/${memberId}`
    );
    dispatch({ type: DELETE_TEAM_MEMBER_SUCCESS, payload: data });
    dispatch({ type: DELETE_TEAM_MEMBER_RESET });
  } catch (error) {
    const errorMessage = error.response
      ? error.response.data.message
      : error.message;
    dispatch({ type: DELETE_TEAM_MEMBER_FAIL, payload: errorMessage });
  }
};

export const verifyPayment =
  (
    hotelId,
    teamId,
    teamMembers,
    tipAmountPerMember,
    review,
    parentTransactionId
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: VERIFY_PAYMENT_REQUEST });
      const { data } = await axios.post(`${BACKEND_URL}payment/payTeam`, {
        hotelId,
        teamId,
        teamMembers,
        tipAmountPerMember,
        review,
        parentTransactionId,
      });
      dispatch({
        type: VERIFY_PAYMENT_SUCCESS,
        payload: {
          success: data.success,
          payouts: data.payouts,
        },
      });
    } catch (error) {
      dispatch({
        type: VERIFY_PAYMENT_FAIL,
        payload: error.response?.data.message || "Payment verification failed",
      });
    }
  };
